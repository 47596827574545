<template>
    <div class="wrap">
        <alert-modal v-if="isAlertModal" :alertText="alertText" @closeModal="isAlertModal = false" />
        <cancel-modal v-if="cancelStatus" />
        <cancel-checkmodal
            v-if="cancelCheckStatus"
            :curationinfo="curationObject"
            :isRegistCard="isRegistCard"
            @closeCancel="cancelCheckStatus = false"
            @showCancel="cancelStatus = true"
            @deletspace="onDeleteSpace"
        />
        <!-- ################### PC ################### -->
        <div class="spaceinsert_pc">
            <curation-infopc
                v-if="showCurationInfo"
                :curationInfo="curationInfo"
                @closeCurationInfo="showCurationInfo = false"
            />
            <section class="space_register_box scroll">
                <div class="space_register_title_box dp_flex flex_space_between">
                    <h1 class="space_register_title">스페이스 등록</h1>
                    <div @click="linkMySpace()" class="dp_flex align_center cursor">
                        <img src="/media/img/space/close.svg" alt="닫기" />
                    </div>
                </div>
                <h3 class="space_register_subtitle font_white">어떤 시대의 음악을 좋아하시나요?</h3>
                <div class="space_progress_bar_wrap">
                    <progress-bar :progresswidth="progressWidth" />
                </div>
                <ul class="dp_flex  fade-up space_peroid_list">
                    <li
                        class="peroid_item_circle cursor"
                        v-for="item in getPeroid"
                        :class="{ active: selectPeroid.includes(item.value) }"
                        :key="item.id"
                        @click="onClickPeroid(item)"
                    >
                        <span class="peroid_item_text">{{ item.text }}</span>
                    </li>
                </ul>
            </section>
        </div>
        <!-- ################### 모바일 ################### -->
        <div class="spaceinsert_mo">
            <curation-infomo
                v-if="showCurationInfo"
                :curationInfo="curationInfo"
                @closeCurationInfo="showCurationInfo = false"
            />
            <!-- 모바일 헤더-->
            <mo-spaceheader @click-pre="onClickPre" @link-myspace="linkMySpace">
                <template #progressbar>
                    <progress-bar :progresswidth="progressWidth" />
                </template>
            </mo-spaceheader>
            <!-- 모바일 바디 -->
            <section class="mo_spaceinsert_body">
                <h3 class="mo_spaceinsert_title">어떤 시대의 음악을 좋아하시나요?</h3>
                <ul class="dp_flex  fade-up space_peroid_list">
                    <li
                        class="peroid_item_circle cursor"
                        v-for="item in moPeroid1"
                        :class="{ active: selectPeroid.includes(item.value) }"
                        :key="item.id"
                        @click="onClickPeroid(item)"
                    >
                        <span class="peroid_item_text">{{ item.text }}</span>
                    </li>
                </ul>
                <ul class="dp_flex fade-up space_peroid_list half">
                    <li
                        class="peroid_item_circle cursor"
                        v-for="item in moPeroid2"
                        :class="{ active: selectPeroid.includes(item.value) }"
                        :key="item.id"
                        @click="onClickPeroid(item)"
                    >
                        <span class="peroid_item_text">{{ item.text }}</span>
                    </li>
                </ul>
            </section>
        </div>
        <!-- footer -->
        <space-bottom
            :btndisabled="btnDisabled"
            :curationlist="bottomList"
            @show-curationinfo="onShowCurationinfo"
            @click-pre="onClickPre"
            @update-curation="onUpdateCuration"
        />
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import {
  checkSubscribe,
  updateCurationAllTemp,
  updateConfirmCuration,
  automaticRecommOfPreferMusic
} from '@/service/api/profileApi';
import { getMyCardInfo } from '@/service/api/productApi';
import { getCurationInfo } from '@/service/api/playerApi';
import { curationValidation, isGoFavoriteMusic, retryAutomaticRecomm } from '@/utils/Utils';
export default defineComponent({
  data () {
    return {
      isAlertModal: false,
      alertText: '',
      selectPeroid: [],
      bottomList: [],
      spaceId: localStorage.getItem('spaceId'),
      curationObject: null,
      cancelCheckStatus: false,
      isRegistCard: false,
      cancelStatus: false,
      userId: this.$cookies.get('userId'),
      showCurationInfo: false,
      curationInfo: null,
      domesticRate: 0,
      genrevalue: [],
      genreTuneNvalue: [],
      genreTuneYvalue: [],
      isBtnDisabled: false,
      progressWidth: 0
    };
  },
  components: {
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`),
    'progress-bar': () => import(`@/components/space/ProgressBar.vue`),
    'cancel-checkmodal': () => import(`@/components/modal/Space/CancelCheckModal.vue`),
    'mo-spaceheader': () => import(`@/components/common/MoSpaceHeader.vue`),
    'space-bottom': () => import(`@/components/space/SpaceBottom.vue`),
    'cancel-modal': () => import(`@/components/modal/Space/CancelModal.vue`),
    'curation-infopc': () => import(`@/components/modal/Space/CurationInfo.vue`),
    'curation-infomo': () => import(`@/components/modal/Space/CurationInfoM.vue`)
  },
  created () {
    this.checkRegistCard();
    this.setCurationInfo();
  },
  methods: {
    onDeleteSpace () {},
    async setCurationInfo () {
      try {
        const { data } = await getCurationInfo(this.spaceId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          this.curationInfo = result;
          const {
            period,
            genre,
            domesticRate,
            spaceType,
            target,
            material,
            color,
            carPurpose,
            genreTuneN,
            genreTuneY
          } = result;

          this.domesticRate = domesticRate;
          const peroidList = this.$store.getters['curation/getPeroid'];
          period.forEach(element => {
            const find = peroidList.find(item => item.value2 === element);
            if (find) {
              this.selectPeroid.push(find.value);
            }
          });

          // 장르
          if (genre) {
            genre.forEach((ele, index) => {
              const genreFind = this.$store.getters['curation/getGenreValue'](ele);
              if (genreFind) {
                this.bottomList.push({
                  cssclass: index === 0 ? 'space_curation_info_box first img' : 'space_curation_info_box img',
                  url: genreFind.imgUrl
                });

                // 장르 리스트 추가
                this.genrevalue.push(parseInt(genreFind.value));
              }
            });
          }

          // 보컬없음
          if (genreTuneN) {
            genreTuneN.forEach(ele => {
              const genreFind = this.$store.getters['curation/getGenreValue'](ele);
              if (genreFind) {
                this.bottomList.push({
                  cssclass:
                    this.genrevalue.length === 0 ? 'space_curation_info_box first img' : 'space_curation_info_box img',
                  url: genreFind.imgUrl
                });

                // 장르 리스트 추가
                this.genreTuneNvalue.push(parseInt(genreFind.value));
              }
            });
          }

          // 보컬있음
          if (genreTuneY) {
            genreTuneY.forEach(ele => {
              const genreFind = this.$store.getters['curation/getGenreValue'](ele);
              if (genreFind) {
                this.bottomList.push({
                  cssclass:
                    this.genrevalue.length === 0 && this.genreTuneNvalue.length === 0
                      ? 'space_curation_info_box first img'
                      : 'space_curation_info_box img',
                  url: genreFind.imgUrl
                });

                // 장르 리스트 추가
                this.genreTuneYvalue.push(parseInt(genreFind.value));
              }
            });
          }

          // 국내곡 비율
          if (domesticRate) {
            const domestictRateFind = this.$store.getters['curation/getDomesticRateValue'](parseInt(domesticRate));
            this.bottomList.push({
              cssclass: 'space_curation_info_box img domesticatrate',
              url: domestictRateFind.imgUrl
            });
          }

          if (spaceType === 'Store') {
            this.progressWidth = 84;

            // 고객층
            if (target) {
              target.forEach(ele => {
                const findTarget = this.$store.getters['curation/getTargetValue'](ele);
                if (findTarget) {
                  this.bottomList.push({
                    cssclass: 'space_curation_info_box img',
                    url: findTarget.imgUrl
                  });
                }
              });
            }
            // 소재
            if (material) {
              material.forEach(ele => {
                const findMaterial = this.$store.getters['curation/getMaterialValue'](ele);
                if (findMaterial) {
                  this.bottomList.push({
                    cssclass: 'space_curation_info_box material',
                    url: findMaterial.materialNm
                  });
                }
              });
            }
            // 색상
            if (color) {
              const findColor = this.$store.getters['curation/getColorItem'](color);
              this.bottomList.push({
                cssclass: 'space_curation_info_box color',
                url: `${findColor.img}`
              });
            }
          } else {
            this.progressWidth = 80;
            // 이용목적
            if (carPurpose) {
              carPurpose.forEach(ele => {
                const carpurposeFind = this.$store.getters['curation/getCarPurPoseValue'](ele);
                if (carpurposeFind) {
                  this.bottomList.push({
                    cssclass: 'space_curation_info_box img',
                    url: carpurposeFind.imgUrl
                  });
                }
              });
            }
          }
        } else {
          this.selectPeroid = [];
        }
      } catch (error) {
        console.error('error : ', error);
      }
    },
    checkRegistCard () {
      getMyCardInfo(this.userId)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            result ? (this.isRegistCard = true) : (this.isRegistCard = false);
          } else {
            this.isRegistCard = false;
          }
        })
        .catch(() => {
          this.isRegistCard = false;
        });
    },
    async linkMySpace () {
      try {
        const { data } = await checkSubscribe(this.spaceId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          if (result === 'S') {
            this.cancelCheckStatus = true;
            // 장르 값 저장
            this.curationObject = {
              spaceId: this.spaceId,
              peroid: this.selectPeroid
                .map(item => parseInt(item))
                .sort((a, b) => a - b)
                .join(',')
            };
          } else {
            this.$router.push({ name: 'Player' }).catch(() => {});
          }
        }
      } catch (error) {
        console.error('linkMySpace error : ', error);
      }
    },
    onClickPeroid (item) {
      const { value } = item;
      const findIndex = this.selectPeroid.findIndex(findItem => parseInt(findItem) === value);
      if (findIndex > -1) {
        this.selectPeroid.splice(findIndex, 1);
      } else {
        this.selectPeroid.push(parseInt(value));
      }
    },
    onShowCurationinfo () {
      this.showCurationInfo = true;
    },
    onClickPre () {
      this.$router.push({ name: 'SpaceInsertGenre' }).catch(() => {});
    },
    async onUpdateCuration () {
      let genre = [];
      if (this.genrevalue.length > 0) {
        genre = [...this.genrevalue];
      }

      if (this.genreTuneNvalue.length > 0) {
        genre = [...genre, ...this.genreTuneNvalue];
      }

      if (this.genreTuneYvalue.length > 0) {
        genre = [...genre, ...this.genreTuneYvalue];
      }

      const { text, isAlert } = curationValidation(
        {
          domesticRate: this.domesticRate
        },
        genre,
        this.selectPeroid
      );

      if (isAlert) {
        this.isAlertModal = isAlert;
        this.alertText = text;

        return true;
      }
      // 큐레이션 업데이트
      const period = this.selectPeroid
        .map(item => parseInt(item))
        .sort((a, b) => a - b)
        .join(',');

      const sendObj = {
        period,
        spaceId: this.spaceId
      };

      try {
        // updateConfirmCuration
        this.isBtnDisabled = true;
        const { data: curationTempData } = await updateCurationAllTemp(sendObj);
        const { resultCd } = curationTempData;
        if (resultCd === '0000') {
          const { data: confirmCurationData } = await updateConfirmCuration(this.spaceId, this.curationInfo.curationId);
          const { resultCd: confirmCurationResultCd } = confirmCurationData;
          if (confirmCurationResultCd === '0000') {
            const isGo = isGoFavoriteMusic(this.genrevalue);
            if (isGo) {
              this.$router.push({ name: 'SpaceInsertStoreFavorite' }).catch(() => {});
            } else {
              const { data: recommPreferData } = await automaticRecommOfPreferMusic(this.curationInfo.curationId);
              const { resultCd: recommPreferResultCd } = recommPreferData;
              if (recommPreferResultCd !== '0000') return false;
              this.$router.push({ path: `/space/insertStore5/${this.curationInfo.curationId}` }).catch(() => {});
            }
          }
        }
      } catch (error) {
        if (error.response) {
          const { url } = error.response.config;
          if (url === '/api/profile/automaticRecommOfPreferMusic') {
            console.log(error.response);
            retryAutomaticRecomm(
              0,
              () => {
                this.isBtnDisabled = false;
              },
              () => {
                this.isBtnDisabled = false;
              }
            );
          }
        }
        console.error('onUpdateCuration  : ', error);
      } finally {
        this.isBtnDisabled = false;
      }
    }
  },
  mounted () {},
  computed: {
    btnDisabled () {
      if (this.selectPeroid.length > 0 && !this.isBtnDisabled) {
        return false;
      }
      return true;
    },
    getPeroid () {
      return this.$store.getters['curation/getPeroid'];
    },
    moPeroid1 () {
      return this.$store.getters['curation/getPeroid'].slice(0, 3);
    },
    moPeroid2 () {
      return this.$store.getters['curation/getPeroid'].slice(3);
    }
  }
});
</script>
<style scoped src="@/assets/css/space/spaceinsert.css"></style>
<style scoped src="@/assets/css/space/spaceperoid.css"></style>
